<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body apply-modal">
                <div class="modal-header">
                    <h3>{{ $t('Apply to Vacancy') }}</h3>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6.8" height="12.6" viewBox="0 0 17.216 30.184">
                            <g>
                                <g>
                                    <path stroke="#000" stroke-width="2px" d="M24.353 16.763L11.307 29.809a1 1 0 0 1-1.415-1.415L22.226 16.05 9.882 3.716A1 1 0 0 1 11.3 2.3l13.043 13.048a1 1 0 0 1 .01 1.415z" transform="translate(-8.544 -0.963) translate(9.64 2.06) translate(-9.64 -2.06)"/>
                                </g>
                            </g>
                        </svg>
                        {{ vacancy.title }}
                    </p>
                </div>
                <form @submit.prevent="register" id="w0" class="zutzu-form">
                    <div class="form-group">
                        <input v-model="user.profile.first_name" type="text" :class="['form-control', {'has-error': errors['profile.first_name']}]" :placeholder="$t('First name')" >
                        <FormErrors v-if="errors[`profile.first_name`]" :errors="errors[`profile.first_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.profile.last_name" type="text" :class="['form-control', {'has-error': errors['profile.last_name']}]" :placeholder="$t('Last name')" >
                        <FormErrors v-if="errors[`profile.last_name`]" :errors="errors[`profile.last_name`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.email" type="email" :class="['form-control', {'has-error': errors['email']}]" :placeholder="$t('Email')">
                        <FormErrors v-if="errors[`email`]" :errors="errors[`email`]" />
                    </div>

                    <div class="form-group phone-input-group">
                        <PhoneInput
                            :classList="[{'has-error': errors['profile.phone_number']}]"
                            @update="setPhone"
                            :phone="user.profile.phone_number"
                        />
                        <FormErrors v-if="errors[`profile.phone_number`]" :errors="errors[`profile.phone_number`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.password" type="password" :class="['form-control', {'has-error': errors['password']}]" :placeholder="$t('Password')">
                        <FormErrors v-if="errors[`password`]" :errors="errors[`password`]" />
                    </div>

                    <div class="form-group">
                        <input v-model="user.password_confirmation" type="password" :class="['form-control', {'has-error': errors['password_confirmation']}]" :placeholder="$t('Repeat password')">
                        <FormErrors v-if="errors[`password_confirmation`]" :errors="errors[`password_confirmation`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.language_id"
                            :searchable="true"
                            :options="languages"
                            :placeholder="$t('Select language')"
                            :class="['form-control', {'has-error': errors['profile.language_id']}]"
                        />
                        <FormErrors v-if="errors[`profile.language_id`]" :errors="errors[`profile.language_id`]" />
                    </div>

                    <div class="form-group">
                        <Multiselect
                            v-model="user.profile.country_id"
                            :searchable="true"
                            :options="countries"
                            :placeholder="$t('Select country')"
                            :class="['form-control', {'has-error': errors['profile.country_id']}]"
                        />
                        <FormErrors v-if="errors[`profile.country_id`]" :errors="errors[`profile.country_id`]" />
                    </div>

                    <!--        @todo    needs to rework a lot of v-ifs in html !MINOR-->
                    <template v-for="(field, index) in applicationcustomfields" :key="field.id">
                        <div v-if="field.inputType==='text'" class="form-group">
                            <input :type="field.inputType" v-model="customFields[index]" :placeholder="field.label" class="form-control">
                        </div>
                        <div v-if="field.inputType==='textarea'" class="form-group">
                            <textarea :type="field.inputType" v-model="customFields[index]" rows="3" :placeholder="field.label" class="form-control"/>
                        </div>
                        <div v-if="field.inputType==='number'" class="form-group">
                            <input :type="field.inputType" v-model="customFields[index]" :placeholder="field.label" class="form-control">
                        </div>
                        <div v-if="field.inputType==='range'" class="form-group">
                            <label class="form-check-label" :for="`range[${index}]`">{{ field.label }}</label>
                            <input :type="field.inputType" v-model="customFields[index]" :min="field.value.split(',').map(item=>item.trim())[0]"
                                    :max="field.value.split(',').map(item=>item.trim())[1]" :id="`range[${index}]`" :placeholder="field.label" class="form-control">
                            {{ customFields[index] }}
                        </div>
                        <div v-if="field.inputType==='radio'" class="form-group">
                            <div v-for="(radio, idx) in field.value.split(',').map(item=>item.trim())" class="form-check">
                                <input class="form-check-input" v-model="customFields[index]" type="radio" :name="`radio${index}`" :id="`radio[${idx}]`" :value="radio">
                                <label class="form-check-label" :for="`radio[${idx}]`">{{ radio }}</label>
                            </div>
                        </div>
                        <div v-if="field.inputType==='dropdown'" class="form-group">
                            <select class="form-control" v-model="customFields[index]">
                                <option value="undefined">{{ field.label }}</option>
                                <option v-for="value in field.value.split(',').map(item=>item.trim())" :value="value">{{ value }}</option>
                            </select>
                        </div>
                        <div v-if="field.inputType==='checkbox'" class="form-group form-check">
                            <input type="checkbox" v-model="customFields[index]" :id="`checkbox[${index}]`" class="form-check-input">
                            <label :for="`checkbox[${index}]`" class="form-check-label">{{ field.label }}</label>
                        </div>
                        <div v-if="field.inputType==='date'" class="form-group">
                            <datepicker class="form-control"
                                        :value="customFields[index]"
                                        @update:modelValue="customFields[index] = moment($event).format('yyyy-MM-DD')"
                                        :placeholder="field.label"
                            />
                        </div>
                        <div v-if="field.inputType==='time'" class="form-group">
                            <label class="form-check-label">{{ field.label }}</label>
                            <input type="time" v-model="customFields[index]" class="form-control">
                        </div>
                        <div v-if="field.inputType==='file'" class="mb-3">
                            <div class="input-group">
                                <div class="custom-file">
                                    <label class="custom-file-label" :for="`file[${index}]`">{{ files[index]?.name || field.label }}</label>
                                    <input type="file" class="custom-file-input" :id="`file[${index}]`"
                                            @change="files[index] = $event.target.files[0]">
                                </div>
                            </div>
                        </div>
                        <div v-if="errors[`files.${applicationcustomfields[index].id}`]" v-for="error in errors[`files.${applicationcustomfields[index].id}`]" :key="error" class="text-danger">
                            {{ error.replace(/The.+?files[^\s]+/i, '') }}
                        </div>
                        <div v-if="field.inputType==='image'" class="mb-3">
                            <div class="input-group">
                                <div class="custom-file">
                                    <label class="custom-file-label" :for="`image[${index}]`">{{ images[index]?.name || field.label }}</label>
                                    <input type="file" class="custom-file-input" :id="`image[${index}]`" accept="image/png,image/jpg,image/jpeg"
                                            @change="images[index] = $event.target.files[0]">
                                </div>
                            </div>
                        </div>
                        <div v-if="errors[`images.${applicationcustomfields[index].id}`]" v-for="error in errors[`images.${applicationcustomfields[index].id}`]" :key="error" class="text-danger">
                            {{ error.replace(/The.+?images[^\s]+/i, '') }}
                        </div>
                    </template>

                    <div class="form-group file-input-group">
                        <label :class="['form-control custom-file-input', {'has-error': errors['cv']}]" for="file-upload">
                            <span>
                                {{ cv?.name ?? $t('Upload CV...') }}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.042" height="21.879" viewBox="0 0 20.042 21.879">
                                <path d="M31.419 19.562l-9.873 9.873a3.956 3.956 0 0 1-5.622 0l-.055-.055a3.982 3.982 0 0 1-1.152-2.825 4.03 4.03 0 0 1 1.152-2.825L27.5 12.1a2.731 2.731 0 0 1 3.867 0l.027.027a2.731 2.731 0 0 1 0 3.867L19.79 27.571a1.473 1.473 0 0 1-2.084 0l-.027-.027a1.457 1.457 0 0 1 0-2.084l9.821-9.819a.64.64 0 0 0-.905-.905l-9.819 9.819a2.781 2.781 0 0 0 0 3.895l.027.027a2.764 2.764 0 0 0 3.895 0L32.269 16.9a4.022 4.022 0 0 0 0-5.677l-.027-.027a4.022 4.022 0 0 0-5.677 0L14.936 22.854a5.224 5.224 0 0 0-1.536 3.73 5.319 5.319 0 0 0 1.536 3.73l.055.055a5.3 5.3 0 0 0 7.46 0l9.873-9.873a.646.646 0 0 0 0-.905.623.623 0 0 0-.905-.029z" transform="translate(-13.4 -10.025)"/>
                            </svg>
                        </label>

                        <input
                            type="file"
                            class="hidden-input"
                            id="file-upload"
                            accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                            @change="cv = $event.target.files[0]" />
                        <FormErrors v-if="errors[`cv`]" :errors="errors[`cv`]" />
                    </div>

                    <button type="submit" class="btn btn-primary" :disabled="disabled">{{ $t('Register') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import { vacancyService } from "@/services/vacancyService";
import Datepicker from 'vue3-datepicker'
import moment from "moment";
import formDataAppend from "@/js/formDataAppend";
import Multiselect from '@vueform/multiselect'
import PhoneInput from "../widgets/PhoneInput";
import {locationService} from '@/services/locationService'

export default {
    name: "QuickApplyComponent",
    data() {
        return {
            user: {
                profile: {},
            },
            customFields: [],
            files: [],
            images: [],
            cv: null,


            applicationcustomfields: [],
            moment: moment,
        }
    },
    props: ['vacancy'],
    computed: {
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    created() {
        this.getApplicationCustomFields();

        locationService.getLocation()
            .then(response => {
                this.user.profile.language_id = response.data.language_id;
                this.user.profile.country_id = response.data.country_id;
                this.user.profile.phone_number = response.data.phone_code;
            });
    },
    methods: {
        register() {
            this.disabled = true

            const formData = new FormData();

            if (this.cv) {
                formData.append('cv', this.cv)
            }

            formDataAppend(formData, this.user)

            this.customFields.forEach((value, index) => {
                let id = this.applicationcustomfields[index].id
                formData.append(`customfields[${id}]`, value)
            })

            this.files.forEach((file, index) => {
                let id = this.applicationcustomfields[index].id
                formData.append(`files[${id}]`, file)
            })

            this.images.forEach((image, index) => {
                let id = this.applicationcustomfields[index].id
                formData.append(`images[${id}]`, image)
            })

            vacancyService.quickApply(this.vacancy.id, formData).then(response => {
                this.errors = []
                this.$store.dispatch('setToken', response.data.access_token)
                this.$store.dispatch('getUser')
                this.$emit('closeModal')
            }).catch(error => {
                this.errors = error.response.data.errors || []
            }).finally(() => this.disabled = false)
        },
        getApplicationCustomFields() {
            vacancyService.getApplicationCustomFields(this.vacancy.id).then(response => this.applicationcustomfields = response.data.data)
        },
        setPhone(phone) {
            this.user.profile.phone_number = phone;
        },
    },
    components: {
        PhoneInput,
        Datepicker,
        FormErrors,
        Modal,
        Multiselect
    }
}
</script>