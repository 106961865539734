import axios from '@/axios';

class VacancyService {
    index(url) {
        // @todo remake this
        return axios.get(url);
    }
    getVacancy(id) {
        return axios.get(`/vacancies/${id}`);
    }
    applyNow(id, formData) {
        return axios.post(`/vacancies/${id}/applynow`, formData);
    }
    quickApply(id, formData) {
        return axios.post(`/vacancies/${id}/quickapply`, formData, {withCredentials: true});
    }
    getApplicationCustomFields(id) {
        return axios.get(`/vacancies/${id}/applicationcustomfields`);
    }
    getVacancyResponsible(vacancyId) {
        return axios.get(`/my/vacancies/${vacancyId}/responsible`);
    }
    setVacancyResponsible(vacancyId, userId) {
        return axios.post(`/my/vacancies/${vacancyId}/responsible`, { user_id: userId });
    }
    removeVacancyResponsible(vacancyId, responsibleId) {
        return axios.delete(`/my/vacancies/${vacancyId}/responsible/${responsibleId}`);
    }
    getCompanies(query) {
        return axios.get(`/vacancies/companies?q=${query}`);
    }
    getCandidates(query) {
        return axios.get(`/vacancies/candidates?q=${query}`);
    }
}

export const vacancyService = new VacancyService();