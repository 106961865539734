<template>
    <div class="container-fluid view view-item vacancy" v-if="vacancy">
        <div class="content-view borderless">

            <div class="header">
                <div class="header-image">
                    <img :src="vacancy.image" :alt="vacancy.title">
                </div>
                <div class="header-content">
                    
                    <router-link :to="`/vacancies/${slugify(vacancy.title)}-${vacancy.id}`" class="open-view">
                        <button class="btn btn-secondary icon-btn" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                <g>
                                    <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                </g>
                            </svg>
                        </button>
                    </router-link>

                    <div class="title">
                        <h1>{{ vacancy.title }}</h1>
                    </div>
                    <ul class="item-list">
                        <li v-if="vacancy.location_description">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                <g>
                                    <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                </g>
                            </svg>
                            <span>{{ vacancy.location_description }}</span>
                        </li>
                        <li v-if="vacancy.salaries?.length">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                <g>
                                    <g>
                                        <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                    </g>
                                </g>
                            </svg>
                            <span v-for="salary in vacancy.salaries" :key="salary">{{ salary.amount_from }} {{ salary.currency }}</span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                                <g>
                                    <g>
                                        <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                    </g>
                                </g>
                            </svg>
                            <span>views</span>
                        </li>
                        <li v-if="vacancy.is_public">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                                <g>
                                    <g>
                                        <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Public vacancy') }}</span>
                        </li>
                        <li v-if="vacancy.is_premium">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                <g>
                                    <g>
                                        <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Premium vacancy') }}</span>
                        </li>
                        <li v-if="employment_dates">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.57" height="17.218" viewBox="0 0 17.57 17.218">
                                <g>
                                    <path d="M17.2 5.064h-1.828v-.906a.762.762 0 0 0-.758-.758.746.746 0 0 0-.74.758v.906H8.7v-.906a.762.762 0 0 0-.763-.758.75.75 0 0 0-.758.758v.906H5.367A2.869 2.869 0 0 0 2.5 7.931v9.82a2.869 2.869 0 0 0 2.867 2.867H17.2a2.869 2.869 0 0 0 2.867-2.867v-9.82A2.881 2.881 0 0 0 17.2 5.064zM5.367 6.563H7.2v.906a.762.762 0 0 0 .758.758.75.75 0 0 0 .758-.758v-.906h5.178v.906a.762.762 0 0 0 .758.758.75.75 0 0 0 .758-.758v-.906h1.79a1.365 1.365 0 0 1 1.369 1.369v2.218H4V7.931a1.365 1.365 0 0 1 1.367-1.368z" transform="translate(-13.5 -196.4) translate(11 193)"/>
                                </g>
                            </svg>
                            <span v-html="employment_dates"></span>
                        </li>
                        <li v-if="hours_per_week">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g>
                                    <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm3.553 12.894l-4-2A1 1 0 0 1 11 12V7a1 1 0 0 1 2 0v4.382l3.447 1.724a1 1 0 1 1-.895 1.789z" transform="translate(-2 -2)"/>
                                </g>
                            </svg>
                            <span v-html="hours_per_week"></span>
                        </li>
                    </ul>

                    <div class="header-buttons">
                        <button @click="showApplyCandidateComponent=true" class="btn btn-secondary">{{ $t('Apply Candidate') }}</button>
                        <router-link :to="{ name: 'EditVacancy', params: {slug: slugify(vacancy.title), id:vacancy.id} }" class="btn btn-secondary" v-if="user_type==='Management'">{{ $t('Edit Vacancy') }}</router-link>
                        <button v-if="loggedIn" @click="showApplyNowComponent=true" type="button" class="btn btn-secondary">{{ $t('Apply now') }}</button>
                        <button v-else @click="showQuickApplyComponent=true" type="button" class="btn btn-secondary">{{ $t('Quick apply') }}</button>
                        <button type="button" class="btn btn-secondary with-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                <g>
                                    <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="top-carousel" v-if="benefits.length">
                <carousel :items-to-show="7">
                    <slide v-for="(benefit, index) in benefits" :key="index">
                        <div class="top-carousel-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9.139" viewBox="0 0 12 9.139">
                                <path d="M4.094 5.872l-2.72-2.7a.238.238 0 0 0-.335 0L-.18 4.4a.238.238 0 0 0 0 .337q2.058 2.04 4.114 4.083a.238.238 0 0 0 .337 0l7.411-7.446a.24.24 0 0 0 0-.337L10.454-.18a.238.238 0 0 0-.337 0z" transform="translate(0.25 0.25)"/>
                            </svg>
                            <p>{{ benefit }}</p>
                        </div>
                    </slide>
                </carousel>
            </div>

            <div class="item-content">
                <div class="tab-controls">
                    <span @click="currentComponent = 'view'" :class="[currentComponent == 'view' ? 'active' : '']">{{ $t('About Vacancy') }}</span>
                    <span @click="currentComponent = 'company'" :class="[currentComponent == 'company' ? 'active' : '']">{{ $t('About Company') }}</span>
                </div>
                <transition name="tab-slide" mode="out-in">
                    <component :is="currentComponent" :vacancy="vacancy"></component>
                </transition>
            </div>
        </div>
    </div>


    <transition name="fade">
        <quick-apply-component v-if="showQuickApplyComponent" @closeModal="showQuickApplyComponent=false" :vacancy="vacancy" />
    </transition>

    <transition name="fade">
        <apply-now-component v-if="showApplyNowComponent" @closeModal="showApplyNowComponent=false" :vacancy="vacancy" />
    </transition>
    
    <template v-if="user_type==='Management'">
        <transition name="fade">
            <add-or-change-responsible-user v-if="showAddResponsibleUser" @closeModal="showAddResponsibleUser=false" @responsibleSelected="setResponsible" />
        </transition>
        
        <transition name="fade">
            <apply-candidate-component v-if="showApplyCandidateComponent" @closeModal="showApplyCandidateComponent=false" :vacancy="vacancy" />
        </transition>

        <transition name="fade">
            <change-price-rate-component v-if="showChangePriceRate" @closeModal="showChangePriceRate=false" :vacancy="vacancy" />
        </transition>
    </template>
</template>

<script>
import {vacancyService} from "@/services/vacancyService";
import slugify from "@/js/slugify";
import QuickApplyComponent from "@/components/vacancies/QuickApplyComponent";
import ApplyNowComponent from "@/components/vacancies/ApplyNowComponent";
import AddOrChangeResponsibleUser from "@/components/companies/AddOrChangeResponsibleUser";
import ApplyCandidateComponent from "@/components/vacancies/ApplyCandidateComponent";
import ChangePriceRateComponent from "@/components/vacancies/ChangePriceRateComponent";
import Item from '@/components/vacancies/View/Item';
import Company from '@/components/vacancies/View/Company';
import { Carousel, Slide } from 'vue3-carousel';

export default {
    name: "ViewVacancy",
    props: {
        _id: {
            default: null
        }
    },
    data() {
        return {
            id: this.$route.params.id,
            vacancy: null,
            showQuickApplyComponent: false,
            showApplyNowComponent: false,
            responsibles: [],
            showAddResponsibleUser: false,
            showSelectCandidate: false,
            showApplyCandidateComponent: false,
            showChangePriceRate: false,
            slugify: slugify,
            currentComponent: 'view'
        }
    },
    created() {
        if (this._id != null) {
            this.id = this._id
        }
        this.getVacancy()
        this.getVacancyResponsible()
    },
    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        },
        user_type() {
            return this.$store.getters.user_type
        },
        benefits() {
            const benefits = [];

            if (this.vacancy.overtime_possible) {
                benefits.push(this.$t('Overtime is possible'));
            }

            if (this.vacancy.accommodation_possible) {
                benefits.push(this.$t('Accommodation provided'));
            }

            if (this.vacancy.accommodation_deduction) {
                benefits.push(this.$t('Deduction for accommodation'));
            }

            if (this.vacancy.transport) {
                benefits.push(this.$t('Transport provided'));
            }

            if (this.vacancy.transport_expenses_covered) {
                benefits.push(this.$t('Transport expenses covered'));
            }

            if (this.vacancy.provide_tools) {
                benefits.push(this.$t('Tools provided'));
            }

            if (this.vacancy.provide_insurance) {
                benefits.push(this.$t('Insurance provided'));
            }

            if (this.vacancy.provide_insurance) {
                benefits.push(this.$t('Insurance provided'));
            }

            if (this.vacancy.provide_travel_expenses) {
                benefits.push(this.$t('Travel expenses covered'));
            }

            return benefits;
        },
        employment_dates() {
            if (this.vacancy.start_date && this.vacancy.end_date) {
                return `<span><b>${this.$t('Start date')}:</b> ${this.vacancy.start_date}</span> <span><b>${this.$t('End date')}:</b> ${this.vacancy.end_date}</span>`;
            } else if (this.vacancy.start_date) {
                return `<b>${this.$t('Start date')}:</b> ${this.vacancy.start_date}`;
            } else if (this.vacancy.end_date) {
                return `<b>${this.$t('End date')}:</b> ${this.vacancy.end_date}`;
            } else {
                return null;
            }
        },
        hours_per_week() {
            if (this.vacancy.hours_per_week_from && this.vacancy.hours_per_week_to) {
                return `${this.vacancy.hours_per_week_from} - ${this.vacancy.hours_per_week_to} ${this.$t('Hours per week')}`;
            } else if (this.vacancy.hours_per_week_from) {
                return `${this.vacancy.hours_per_week_from} ${this.$t('Hours per week')}`;
            } else if (this.vacancy.hours_per_week_to) {
                return `${this.vacancy.hours_per_week_to} ${this.$t('Hours per week')}`;
            } else {
                return null;
            }
        }
    },
    watch: {
        user_type(val) {
            this.getVacancyResponsible()
        }
    },
    methods: {
        getVacancy() {
            vacancyService.getVacancy(this.id).then(response => this.vacancy = response.data.data)
        },
        getVacancyResponsible() {
            if (this.user_type === 'Management') {
                vacancyService.getVacancyResponsible(this.id).then(response => this.responsibles = response.data.data)
            }
        },
        setResponsible(user) {
            vacancyService.setVacancyResponsible(this.id, user.id).then(() => this.responsibles.push(user))

            this.showAddResponsibleUser = false
        },
        removeResponsible(user) {
            vacancyService.removeVacancyResponsible(this.id, user.id).then(() => {
                let index = this.responsibles.indexOf(user)
                if (index >= 0) this.responsibles.splice(index, 1)
            })
        }
    },
    components: {
        'view': Item,
        'company': Company,
        QuickApplyComponent,
        ApplyNowComponent,
        AddOrChangeResponsibleUser,
        ApplyCandidateComponent,
        ChangePriceRateComponent,
        Carousel,
        Slide
    }
}
</script>