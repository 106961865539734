import axios from '@/axios';

class ResponsibleService {
    index() {
        return axios.get('/management/responsibles');
    }
    setCompanyResponsible(companyId, userId) {
        return axios.post(`/companies/${companyId}/responsible`, { user_id: userId });
    }
}

export const responsibleService = new ResponsibleService();