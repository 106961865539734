<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Select responsible') }}</h3>
                </div>
                <select v-model="selected" @change="responsibleSelected" class="form-control">
                    <option value="">{{ $t('Select') }}</option>
                    <option v-for="user in users" :value="user" :key="user">{{ user.first_name }} {{ user.last_name }} ({{ user.group }})</option>
                </select>

                <button @click="$emit('closeModal')" class="btn btn-secondary">{{ $t('Cancel') }}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import {responsibleService} from "@/services/responsibleService"

export default {
    name: "AddOrChangeResponsibleUser",
    data() {
        return {
            selected: '',
        }
    },
    created() {
        if (this.users.length === 0) {
            this.getResponsibles()
        }
    },
    computed: {
        users() {
            return this.$store.state.responsible.users
        }
    },
    emits: ['responsibleSelected'],
    methods: {
        responsibleSelected() {
            if (this.selected) {
                this.$emit('responsibleSelected', this.selected)
            }
        },
        getResponsibles() {
            responsibleService.index()
                .then(response => {
                    this.$store.commit('setResponsibleUsers', response.data.data)
                })
        }
    },
    components: {
        Modal
    }
}
</script>

<style scoped>

</style>