<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Apply candidate for {vacancy}', {vacancy: vacancy.title}) }}</h3>
                </div>
                <form @submit.prevent="register" id="w0" class="zutzu-form">
                    <div class="form-group">
                        <h3 :class="color">{{ name || 'Select Candidate' }}</h3>

                        <Autocomplete @input="getCandidates"
                                        @onSelect="selected"
                                        debounce="500"
                                        :results="candidates"
                        />
                        <FormErrors v-if="errors[`id`]" :errors="errors[`id`]" />
                    </div>

                    <div class="form-group">
                        <select :class="['form-control', {'has-error': errors['vacancy_salary']}]" v-model="candidate.vacancy_salary">
                            <option :value="undefined">{{ $t('Select salary') }}</option>
                            <option v-for="salary in vacancy.salaries" :value="salary" :key="salary">
                                {{ salary.employment }} {{ salary.amount_from }}-{{ salary.amount_to }} {{ salary.currency }} {{ salary.period }} {{ salary.type }}
                            </option>
                        </select>
                        <FormErrors v-if="errors[`vacancy_salary`]" :errors="errors[`vacancy_salary`]" />
                    </div>

                    <div class="form-group">
                        <textarea v-model="candidate.candidate_message" :class="['form-control', {'has-error': errors['candidate_message']}]" :placeholder="$t('Description about yourself')"/>
                        <FormErrors v-if="errors[`candidate_message`]" :errors="errors[`candidate_message`]" />
                    </div>

                    <!--        @todo    needs to rework a lot of v-ifs in html !MINOR-->
                    <template v-for="(field, index) in applicationCustomFields" :key="field.id">
                        <div v-if="field.inputType==='text'" class="form-group">
                            <input :type="field.inputType" v-model="customFields[index]" :placeholder="field.label" class="form-control">
                        </div>
                        <div v-if="field.inputType==='textarea'" class="form-group">
                            <textarea :type="field.inputType" v-model="customFields[index]" rows="3" :placeholder="field.label" class="form-control"/>
                        </div>
                        <div v-if="field.inputType==='number'" class="form-group">
                            <input :type="field.inputType" v-model="customFields[index]" :placeholder="field.label" class="form-control">
                        </div>
                        <div v-if="field.inputType==='range'" class="form-group">
                            <label class="form-check-label" :for="`range[${index}]`">{{ field.label }}</label>
                            <input :type="field.inputType" v-model="customFields[index]" :min="field.value.split(',').map(item=>item.trim())[0]"
                                    :max="field.value.split(',').map(item=>item.trim())[1]" :id="`range[${index}]`" :placeholder="field.label" class="form-control">
                            {{ customFields[index] }}
                        </div>
                        <div v-if="field.inputType==='radio'" class="form-group">
                            <div v-for="(radio, idx) in field.value.split(',').map(item=>item.trim())" class="form-check">
                                <input class="form-check-input" v-model="customFields[index]" type="radio" :name="`radio${index}`" :id="`radio[${idx}]`" :value="radio">
                                <label class="form-check-label" :for="`radio[${idx}]`">{{ radio }}</label>
                            </div>
                        </div>
                        <div v-if="field.inputType==='dropdown'" class="form-group">
                            <select class="form-control" v-model="customFields[index]">
                                <option value="undefined">{{ field.label }}</option>
                                <option v-for="value in field.value.split(',').map(item=>item.trim())" :value="value">{{ value }}</option>
                            </select>
                        </div>
                        <div v-if="field.inputType==='checkbox'" class="form-group form-check">
                            <input type="checkbox" v-model="customFields[index]" :id="`checkbox[${index}]`" class="form-check-input">
                            <label :for="`checkbox[${index}]`" class="form-check-label">{{ field.label }}</label>
                        </div>
                        <div v-if="field.inputType==='date'" class="form-group">
                            <datepicker class="form-control"
                                        :value="customFields[index]"
                                        @update:modelValue="customFields[index] = moment($event).format('yyyy-MM-DD')"
                                        :placeholder="field.label"
                            />
                        </div>
                        <div v-if="field.inputType==='time'" class="form-group">
                            <label class="form-check-label">{{ field.label }}</label>
                            <input type="time" v-model="customFields[index]" class="form-control">
                        </div>
                        <div v-if="field.inputType==='file'" class="mb-3">
                            <div class="input-group">
                                <div class="custom-file">
                                    <label class="custom-file-label" :for="`file[${index}]`">{{ files[index]?.name || field.label }}</label>
                                    <input type="file" class="custom-file-input" :id="`file[${index}]`"
                                            @change="files[index] = $event.target.files[0]">
                                </div>
                            </div>
                        </div>
                        <div v-if="errors[`files.${applicationCustomFields[index].id}`]" v-for="error in errors[`files.${applicationCustomFields[index].id}`]" :key="error" class="text-danger">
                            {{ error.replace(/The.+?files[^\s]+/i, '') }}
                        </div>
                        <div v-if="field.inputType==='image'" class="mb-3">
                            <div class="input-group">
                                <div class="custom-file">
                                    <label class="custom-file-label" :for="`image[${index}]`">{{ images[index]?.name || field.label }}</label>
                                    <input type="file" class="custom-file-input" :id="`image[${index}]`" accept="image/png,image/jpg,image/jpeg"
                                            @change="images[index] = $event.target.files[0]">
                                </div>
                            </div>
                        </div>
                        <div v-if="errors[`images.${applicationCustomFields[index].id}`]" v-for="error in errors[`images.${applicationCustomFields[index].id}`]" :key="error" class="text-danger">
                            {{ error.replace(/The.+?images[^\s]+/i, '') }}
                        </div>
                    </template>

                    <div class="mb-3">
                        <div class="input-group">
                            <div class="custom-file">
                                <label class="custom-file-label" for="image">{{ cv?.name || 'Upload CV...' }}</label>
                                <input type="file" class="custom-file-input" id="image"
                                        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                        @change="cv = $event.target.files[0]">
                            </div>
                        </div>
                        <div v-if="errors[`cv`]" v-for="error in errors[`cv`]" :key="error" class="text-danger">
                            {{ error.replace(/The.+?cv/i, '') }}
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary" :disabled="disabled">{{ $t('Apply candidate') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';
import { vacancyService } from "@/services/vacancyService";
import moment from "moment";
import formDataAppend from "@/js/formDataAppend";
import Datepicker from "vue3-datepicker";
import Autocomplete from 'vue3-autocomplete'
import 'vue3-autocomplete/dist/vue3-autocomplete.css'

export default {
    name: "ApplyCandidateComponent",
    data() {
        return {
            candidate: {},
            candidates: [],
            customFields: [],
            files: [],
            images: [],
            cv: null,


            applicationCustomFields: [],
            moment: moment,
            name: null,
            color: '',
        }
    },
    props: ['vacancy'],
    created() {
        this.getApplicationCustomFields()
    },
    methods: {
        register() {
            if (! this.candidate.id) {
                this.color = 'text-danger'
                return
            }

            this.disabled = true

            const formData = new FormData();

            if (this.cv) {
                formData.append('cv', this.cv)
            }

            formDataAppend(formData, this.candidate)

            this.customFields.forEach((value, index) => {
                let id = this.applicationCustomFields[index].id
                formData.append(`customfields[${id}]`, value)
            })

            this.files.forEach((file, index) => {
                let id = this.applicationCustomFields[index].id
                formData.append(`files[${id}]`, file)
            })

            this.images.forEach((image, index) => {
                let id = this.applicationCustomFields[index].id
                formData.append(`images[${id}]`, image)
            })

            vacancyService.applyNow(this.vacancy.id, formData).then(() => {
                this.disabled = false
                this.errors = []
                this.$emit('closeModal')
            }).catch(error => {
                this.disabled = false
                this.errors = error.response.data.errors || []
            })
        },
        getApplicationCustomFields() {
            vacancyService.getApplicationCustomFields(this.vacancy.id).then(response => this.applicationCustomFields = response.data.data)
        },
        selected(selected) {
            this.candidate.id = selected.id
            this.name = selected.name
            this.color = ''
        },
        getCandidates(query) {
            if (query.length) {
                vacancyService.getCandidates(query).then(response => this.candidates = response.data.data)
            }
        }
    },
    components: {
        Datepicker,
        Autocomplete,
        Modal,
        FormErrors
    }
}
</script>