<template>
    <div class="item-tab">
        <div class="item-content-section">
            <h2>{{ $t("Company name") }}</h2>
            <div class="single-item">
                <p class="single-item-title">{{ vacancy.company.name }}</p>
                <p>company description @todo</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['vacancy']
}
</script>