<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Change pricerate') }}</h3>
                </div>
                <form @submit.prevent="changePriceRate" class="invite-form">

                    <div class="form-group">
                        <input v-model="price_rate" type="number" step="0.01" :class="['form-control', {'has-error': errors['price_rate']}]" :placeholder="$t('Price rate')" :disabled="disabled">

                        <FormErrors v-if="errors['price_rate']" :errors="errors['price_rate']"  />
                    </div>
                    <button class="btn btn-primary" :disabled="disabled">{{ $t('Update') }}</button>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/widgets/Modal';
import FormErrors from "@/components/widgets/FormErrors";
import {myVacancyService} from "@/services/myVacancyService";

export default {
    name: "ChangePriceRateComponent",
    data() {
        return {
            price_rate: null,
            disabled: true,
        }
    },
    props: ['vacancy'],
    created() {
        myVacancyService.getVacancyPriceRate(this.vacancy.id)
            .then(response => {
                this.price_rate = response.data.price_rate
                this.disabled = false
            })
    },
    methods: {
        hideComponent() {
            this.$emit('closeModal')
        },
        changePriceRate() {
            this.disabled = true
            myVacancyService.setVacancyPriceRate(this.vacancy.id, { price_rate: this.price_rate })
                .then(() => this.$emit('closeModal'))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    },
    components: {
        FormErrors,
        Modal
    },
}
</script>