const formDataAppend = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            formDataAppend(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = (data == null ? '' : data);

        formData.append(parentKey, value);
    }
}

export default formDataAppend