<template>
    <div class="closable-modal modal" tabindex="-1" role="dialog" @click="shouldClose($event)">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <slot name="modalContent" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        closeDefault: {
            default: true
        }
    },
    methods: {
        shouldClose(event) {
            if (event.target.classList.contains('closable-modal') && this.closeDefault) {
                this.$emit('closeModal');
            }
        }
    }
}
</script>