<template>
    <div class="item-tab">
        <div class="item-content-section" v-if="vacancy.description">
            <h2>{{ $t("Description") }}</h2>
            <div v-html="vacancy.description"></div>
        </div>

        <div class="item-content-section" v-if="vacancy.requirements">
            <h2>{{ $t("Requirements") }}</h2>
            <div v-html="vacancy.requirements"></div>
        </div>

        <div class="item-content-section" v-if="vacancy.responsibilities">
            <h2>{{ $t("Responsibilities") }}</h2>
            <div v-html="vacancy.responsibilities"></div>
        </div>

        <div class="item-content-section" v-if="vacancy.we_offer">
            <h2>{{ $t("We offer") }}</h2>
            <div v-html="vacancy.we_offer"></div>
        </div>

        <div v-if="user_type==='Management' && vacancy.responsibles?.length" class="item-content-section">
            <h2>{{ $t('Responsible') }}</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t('Full Name') }}</th>
                        <th scope="col">{{ $t('E-mail') }}</th>
                        <th scope="col">{{ $t('Group') }}</th>
                        <th scope="col">{{ $t('Active') }}</th>
                        <th scope="col">{{ $t('Last Login date') }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="responsible in responsibles" :key="responsible.id">
                        <td>{{ responsible.id }}</td>
                        <td>{{ responsible.first_name }} {{ responsible.last_name }}</td>
                        <td>{{ responsible.email }}</td>
                        <td>{{ responsible.group }}</td>
                        <td>{{ responsible.active ? $t('Active') : $t('Not active') }}</td>
                        <td>{{ responsible.last_login || 'N/A' }}</td>
                        <td>
                            <router-link :to="{ name: 'ViewUser', params: { id: responsible.id } }">{{ $t('view') }}</router-link>
                            /
                            <a href="#" @click.prevent="removeResponsible(responsible)">{{ $t('remove') }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <button class="btn btn-primary" @click="showAddResponsibleUser=true">{{ $t('Add Responsible') }}</button>
            <button class="btn btn-primary" @click="showChangePriceRate=true">{{ $t('Change Price Rate') }}</button>
        </div>
        
        <div class="item-content-section" v-if="vacancy.if_accommodation_deducted_price_per_week || vacancy.accommodation_type">
            <h2>{{ $t("Accommodation") }}</h2>
            <div class="single-item">
                <div class="item-details-table">
                    <div class="detail">
                        <p class="detail-title">{{ $t('If Accommodations deduction price per week') }}</p>
                        <p>{{ vacancy.if_accommodation_deducted_price_per_week ? vacancy.if_accommodation_deducted_price_per_week : '-' }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Accommodations type') }}</p>
                        <p>{{ vacancy.accommodation_type ? vacancy.accommodation_type : '-' }}</p>
                    </div>
                </div>
            </div>                
        </div>

        <div class="item-content-section" v-if="vacancy.tags?.length">
            <h2>{{ $t("Tags") }}</h2>
            <div class="single-item badge-item" v-for="(tag, index) in vacancy.tags" :key="index">
                <p>{{ tag }}</p>
            </div>
        </div>

        <div class="item-content-section">
            <h2>{{ $t("Provide") }}</h2>
            <div v-html="vacancy.provide_other"></div>
        </div>

        <div class="item-content-section" v-if="vacancy.salaries?.length">
            <h2>{{ $t("Salaries") }}</h2>
            <div class="single-item" v-for="salary in vacancy.salaries" :key="salary">
                <div class="item-details-table">
                    <div class="detail">
                        <p class="detail-title">{{ $t('Min') }}</p>
                        <p>{{ salary.amount_from }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Max') }}</p>
                        <p>{{ salary.amount_to }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Currency') }}</p>
                        <p>{{ salary.currency }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Type') }}</p>
                        <p>{{ salary.type }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Employment type') }}</p>
                        <p>{{ salary.employment }}</p>
                    </div>
                </div>
                <p>{{ salary.description }}</p>
            </div>                
        </div>

        <div class="item-content-section" v-if="vacancy.languages?.length">
            <h2>{{ $t("Languages") }}</h2>
            <div class="single-item badge-item" v-for="language in vacancy.languages" :key="language">
                <p>{{ language.name }} - {{ language.level }}</p>
            </div>
        </div>

        <div class="item-content-section">
            <h2>{{ $t("Contacts") }}</h2>
            <div class="single-item">
                <div class="item-details-table">
                    <div class="detail">
                        <p class="detail-title">{{ $t('First name') }}</p>
                        <p>{{ vacancy.contact?.first_name ? vacancy.contact.first_name : '-' }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Last name') }}</p>
                        <p>{{ vacancy.contact?.last_name ? vacancy.contact.last_name : '-' }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Persons phone') }}</p>
                        <p>{{ vacancy.contact?.phone_number ? vacancy.contact.phone_number : '-' }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Email') }}</p>
                        <p>{{ vacancy.email ? vacancy.email : '-' }}</p>
                    </div>
                    <div class="detail">
                        <p class="detail-title">{{ $t('Phone') }}</p>
                        <p>{{ vacancy.phone ? vacancy.phone : '-' }}</p>
                    </div>
                </div>
            </div>
        </div>

<!--         <div class="item-content-section">
            <h2>{{ $t("Other information") }}</h2>
            <ul>
                <li v-for="customField in vacancy.customFields" :key="customField.id">
                    <span>{{ customField.label }}</span> {{ customField.value }}
                </li>
            </ul>
        </div> -->

        <hr />

        <div class="item-footer-buttons">
            <button class="btn btn-secondary">{{ $t('Edit Full Vacancy') }}</button>

            <button type="button" class="btn btn-secondary with-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                    <g>
                        <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['vacancy']
}
</script>